<template>
  <div
    style="cursor: pointer"
    :style="'width:' + size + 'px; height: ' + size + 'px;'"
  >
    <img
      :src="station.logo"
      class="w-full object-cover"
      :class="extraClass"
      style="aspect-ratio: 1; border-radius: 15px"
      alt="Radio station"
    />
  </div>
</template>

<script>
export default {
  props: {
    station: {
      type: Object,
      default: () => {}
    },
    size: {
      type: Number,
      default: 160
    },
    extraClass: {
      type: String,
      required: false
    }
  },
  data() {
    return {}
  },
  methods: {},
  created() {}
}
</script>
