<template>
  <div class="w-full flex">
    <div class="w-full lg:w-4/6">
      <div v-for="group in groupedStations" :key="'category-' + group.category.id" class="mb-5 p-5 md:p-3">
        <p class="text-black dark:text-white text-md sm:text-lg md:text-2xl mb-3">
          {{ group.category[$i18n.locale + "_name"] }}
        </p>
        <carousel :nav="false" :dots="false" :autoWidth="true" class="carousel-card relative" :margin="15" :responsive="{
          0: { items: 1, nav: false },
          340: { items: 2, nav: false },
          650: { items: 3, nav: false },
          800: { items: 4, nav: false }
        }">
          <station-cover v-for="station in group.stations" :key="`station-${station.id}`" :station="station"
            @click.native="playRadio(station)" />
          <template slot="prev"><span
              class="absolute bg-white top-1/3 left-0 p-2 rounded-lg shadow-xl z-10 cursor-pointer"
              style="border: 1px solid #a0a0a0">
              <icon icon="angle-left" />
            </span></template>
          <template slot="next"><span
              class="absolute bg-white top-1/3 right-0 p-2 rounded-lg shadow-2xl z-10 cursor-pointer"
              style="border: 1px solid #a0a0a0">
              <icon icon="angle-right" />
            </span></template>
        </carousel>
      </div>
    </div>

    <div class="hidden lg:w-2/6">
      <t-card v-if="!suggestedStationsLoading" :variant="theme === 'dark' ? 'dark' : 'light'"
        class="md:w-2/5 lg:w-2/6 fixed pl-5" style="cursor: pointer">
        <template v-slot:header>
          <p class="text-black dark:text-white px-2 text-center font-bold">
            {{ $i18n.t("recommended_stations") }}
          </p>
        </template>

        <div class="my-1 w-full" v-for="station in suggestedStations.slice(0, itemsAmount)"
          :key="'recommended-station-' + station.id">
          <div class="flex justify-start" @click="playRadio(station)">
            <img style="max-width: 100px; aspect-ratio: 1" src="/static/logo_square.png"
              :ref="'loading-picture-' + station.id" />
            <img class="hidden" style="max-width: 100px; aspect-ratio: 1; border-radius: 15px" :src="station.logo"
              alt="Station logo" @load="onImageLoad(station.id)" :ref="'station-logo-' + station.id" />
            <div class="my-auto">
              <p class="text-black dark:text-white px-2 font-bold"
                style="text-overflow: ellipsis; word-wrap: break-word" :style="'font-size: ' + smallFontSize">
                {{
                    station.description.length > 90
                      ? station.description.slice(0, 90) + "..."
                      : station.description
                }}
              </p>
            </div>
          </div>
        </div>
      </t-card>
      <img v-if="suggestedStationsLoading" class="w-1/2 md:w-1/6 lg:w-1/5 mx-auto mt-24" :src="
  theme === 'dark'
    ? '/static/loading_darkmode.gif'
    : '/static/loading_lightmode.gif'
      " alt="loading-gif" />
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel"
import StationCover from "@/components/radio/StationCover.vue"
import { debounce } from "lodash"
const notify = () => import("@/utils/notify.js")

export default {
  data() {
    return {
      suggestedStationsLoading: true,
      itemsAmount: Math.floor((window.screen.height * 0.74) / 100)
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    },
    groupedStations() {
      return this.$store.getters["radio/getAllUnpaginated"]
    },
    suggestedStations() {
      return this.$store.getters["radio/getAllSuggested"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
    smallFontSize() {
      return "0.80rem"
    }
  },
  components: {
    carousel,
    StationCover
  },
  methods: {
    onImageLoad(id) {
      if (this.$refs["loading-picture-" + id][0])
        this.$refs["loading-picture-" + id][0].classList.add("hidden")
      if (this.$refs["station-logo-" + id][0])
        this.$refs["station-logo-" + id][0].classList.remove("hidden")
    },
    playRadio(radio) {
      this.$store.dispatch("radio/setItem", radio)
      this.$emit("update:activeRadio", true)
    },
    getGroupedStations() {
      this.$store
        .dispatch("global/getItems", {
          route: "/station/all",
          module: "radio",
          noPaginate: true,
          params: {
            grouped: "yes",
            active: 1
          }
        })
        .then((response) => {
          this.$emit("finishLoading")
        })
        .catch((error) => {
          this.$emit("finishLoading")
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    getSuggestedRadios() {
      this.$store
        .dispatch("global/getItems", {
          route: "/station/all",
          module: null,
          noPaginate: true,
          params: {
            order_key: "ordering",
            order_value: "asc",
            limit: 5,
            active: 1
          }
        })
        .then((response) => {
          this.$store.dispatch("radio/setSuggestedElements", response.data)
          this.suggestedStationsLoading = false
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            this.suggestedStationsLoading = false
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    debouncedResize() {
      this.itemsAmount = Math.floor((window.screen.height * 0.74) / 100)
    }
  },
  created() {
    this.$emit("startLoading")
    this.getGroupedStations()
    this.getSuggestedRadios()
    window.addEventListener("resize", debounce(this.debouncedResize, 500))
  },
  beforeDestroy() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  unmounted() {
    window.removeEventListener("resize", debounce(this.debouncedResize, 500))
  },
  watch: {}
}
</script>
<style>
.carousel-card .owl-nav {
  display: none;
}
</style>
